export default {
    en: {
        browseMenuOrder: 'Browse the menu and order directly from your phone!',
        viewOurMenus: 'Tap To View Our Menus',
        ourMenus: 'Our Menus',
        selectLanguage: 'Select Language',
        payLaterTotal: 'Current Table Bill:',
        notAcceptingOrdersPickup:
            'This restaurant is currently not accepting pickup orders.',
        notAcceptingOrdersDelivery:
            'This restaurant is currently not accepting delivery orders.',
        minimumOrderValue: 'Minimum order value for delivery orders:',
        notAcceptingOrders:
            'This restaurant is currently not accepting orders.',
        discountsAvailable: '% Discounts Available %',
        orderFeeMsg: 'Each order incurs a fee of',
        menuLanguage: 'Menu Language:',
        spend: 'Spend',
        get: 'Get',
        per_day: 'per day',
        get_caps: 'Get',
        select_gift_card_id:
            "Please select a gift card you'd like to purchase.",
        select_sub: "Please select a subscription you'd like to purchase.",
        continue_delitepay_tapped: 'Continue with DelitePay',
        continue_deliteclub_tapped: 'Continue with DeliteClub',
        not_accepting_orders:
            'This restaurant is currently not accepting orders.',
        discount_tags_available: '% Discounts Available %',
        view_our_menus: 'Tap To View Our Menus',
        our_menus: 'Our Menus',
        items_3: 'free items',
        specify_email: 'Please specify your email.',
        select_language: 'Select Language',
        select_menu: 'Select Menu',
        delivery: 'Delivery',
        pickup: 'Pickup',
        dine_in: 'Dine-In',
        tap_to_start: 'Tap to Start',
        view_pickup_menu: 'View Pickup Menu',
        view_delivery_menu: 'View Delivery Menu',
        view_pickup_delivery_menus: 'View Pickup / Delivery Menus',
        your_total_colon: 'Your Total:',
        including_order_fee: '(Incl. Order Fee)',
        place_order: 'Place Order',
        go_back: 'Go Back',
        room_number_needed: 'Please enter room number.',
        email_needed_hotel: 'Please enter your email.',
        specify_name: 'Please fill in all fields.',
        order_confirmation: 'Order Confirmation',
        your_information: 'Your Information',
        any_allergies_short: 'Any Allergies or Notes?',
        enter_room_number: 'Enter Your Room Number:',
        enter_table_code: 'Table Code:',
        incorrect_room_number: 'Incorrect Room Number',
        where_should_we_deliver: 'Where Should We Deliver?',
        my_room: 'My Room',
        your_email: 'Enter Your Email:',
        email_expl:
            'This email will be used for your receipt as well as bonus points.',
        incorrect_email_format: 'Incorrect Email Format',
        your_full_name: 'Your Full Name:',
        incorrect_name_format: 'Incorrect Name Format',
        your_phone_number: 'Your Phone Number:',
        incorrect_phone_number_format: 'Incorrect Phone Number Format',
        your_total_short: 'Your Total',
        please_ensure_paid_hotel:
            'This order will be charged to your room. Please ensure you settle the bill with the hotel before checking out.',
        please_ensure_paid_1:
            'Please ensure you pay for your order before leaving the venue.',
        please_ensure_paid:
            'Please ensure you pay for your order before leaving the venue. You can do this by scanning the QR code again and clicking on the "Current Table Bill" button, to select the items you wish to pay for.',
    },
    es: {
        browseMenuOrder:
            '¡Revisa el menú y ordena directamente desde tu teléfono!',
        viewOurMenus: 'Toca para ver nuestros menús',
        ourMenus: 'Nuestros Menús',
        selectLanguage: 'Seleccionar idioma',
        payLaterTotal: 'Factura actual de la mesa:',
        notAcceptingOrdersPickup:
            'Este restaurante no está aceptando pedidos para recoger en este momento.',
        notAcceptingOrdersDelivery:
            'Este restaurante no está aceptando pedidos para entrega en este momento.',
        minimumOrderValue: 'Valor mínimo del pedido para pedidos de entrega:',
        notAcceptingOrders:
            'Este restaurante no está aceptando pedidos en este momento.',
        discountsAvailable: '% Descuentos Disponibles %',
        orderFeeMsg: 'Cada pedido tiene una tarifa de',
        menuLanguage: 'Idioma del Menú:',
        spend: 'Gasta',
        get: 'Obtén',
        per_day: 'por día',
        get_caps: 'Obtén',
        select_gift_card_id:
            'Por favor, selecciona una tarjeta de regalo que te gustaría comprar.',
        select_sub:
            'Por favor, selecciona una suscripción que te gustaría comprar.',
        continue_delitepay_tapped: 'Continuar con DelitePay',
        continue_deliteclub_tapped: 'Continuar con DeliteClub',
        not_accepting_orders:
            'Este restaurante no está aceptando pedidos en este momento.',
        discount_tags_available: '% Descuentos Disponibles %',
        view_our_menus: 'Toca para ver nuestros menús',
        our_menus: 'Nuestros Menús',
        items_3: 'artículos gratis',
        specify_email: 'Por favor, especifica tu correo electrónico.',
        select_language: 'Seleccionar idioma',
        select_menu: 'Seleccionar Menú',
        delivery: 'Entrega',
        pickup: 'Recoger',
        dine_in: 'Comer en el sitio',
        tap_to_start: 'Toca para comenzar',
        view_pickup_menu: 'Ver menú para recoger',
        view_delivery_menu: 'Ver menú para entrega',
        view_pickup_delivery_menus: 'Ver menús para recoger/entrega',
        your_total_colon: 'Tu Total:',
        including_order_fee: '(Incluye Tarifa de Pedido)',
        place_order: 'Realizar Pedido',
        go_back: 'Regresar',
        room_number_needed: 'Por favor, ingrese el número de habitación.',
        email_needed_hotel: 'Por favor, ingrese su correo electrónico.',
        specify_name: 'Por favor, complete todos los campos.',
        order_confirmation: 'Confirmación del Pedido',
        your_information: 'Tu Información',
        any_allergies_short: '¿Alguna alergia o nota?',
        enter_room_number: 'Ingrese su número de habitación:',
        enter_table_code: 'Código de Mesa:',
        incorrect_room_number: 'Número de habitación incorrecto',
        where_should_we_deliver: '¿Dónde debemos entregar?',
        my_room: 'Mi habitación',
        your_email: 'Ingresa tu correo electrónico:',
        email_expl:
            'Este correo electrónico se usará para su recibo y también para puntos de bonificación.',
        incorrect_email_format: 'Formato de correo electrónico incorrecto',
        your_full_name: 'Tu Nombre Completo:',
        incorrect_name_format: 'Formato de nombre incorrecto',
        your_phone_number: 'Tu Número de Teléfono:',
        incorrect_phone_number_format:
            'Formato de número de teléfono incorrecto',
        your_total_short: 'Tu Total',
        please_ensure_paid_hotel:
            'Este pedido se cargará a su habitación. Asegúrese de liquidar la cuenta con el hotel antes de hacer el check-out.',
        please_ensure_paid_1:
            'Por favor, asegúrese de pagar su pedido antes de salir del lugar.',
        please_ensure_paid:
            'Por favor, asegúrese de pagar su pedido antes de salir del lugar. Puede hacerlo escaneando el código QR nuevamente y haciendo clic en el botón "Factura de Mesa Actual", para seleccionar los artículos que desea pagar.',
    },
    zh: {
        browseMenuOrder: '浏览菜单并直接从手机下单！',
        viewOurMenus: '点击查看我们的菜单',
        ourMenus: '我们的菜单',
        selectLanguage: '选择语言',
        payLaterTotal: '当前桌账单:',
        notAcceptingOrdersPickup: '这家餐厅目前不接受取餐订单。',
        notAcceptingOrdersDelivery: '这家餐厅目前不接受外卖订单。',
        minimumOrderValue: '送货订单的最低订单金额:',
        notAcceptingOrders: '这家餐厅目前不接受订单。',
        discountsAvailable: '% 可用折扣 %',
        orderFeeMsg: '每个订单需支付一笔费用',
        menuLanguage: '菜单语言:',
        spend: '消费',
        get: '获得',
        per_day: '每天',
        get_caps: '获得',
        select_gift_card_id: '请选择您要购买的礼品卡。',
        select_sub: '请选择您要购买的订阅。',
        continue_delitepay_tapped: '继续使用 DelitePay',
        continue_deliteclub_tapped: '继续使用 DeliteClub',
        not_accepting_orders: '这家餐厅目前不接受订单。',
        discount_tags_available: '% 可用折扣 %',
        view_our_menus: '点击查看我们的菜单',
        our_menus: '我们的菜单',
        items_3: '免费商品',
        specify_email: '请提供您的电子邮件。',
        select_language: '选择语言',
        select_menu: '选择菜单',
        delivery: '送货',
        pickup: '取货',
        dine_in: '堂食',
        tap_to_start: '点击开始',
        view_pickup_menu: '查看取餐菜单',
        view_delivery_menu: '查看送餐菜单',
        view_pickup_delivery_menus: '查看取餐/送餐菜单',
        your_total_colon: '你的总计:',
        including_order_fee: '(包括订单费用)',
        place_order: '下订单',
        go_back: '返回',
        room_number_needed: '请输入房间号。',
        email_needed_hotel: '请输入您的电子邮件。',
        specify_name: '请填写所有字段。',
        order_confirmation: '订单确认',
        your_information: '您的信息',
        any_allergies_short: '有任何过敏或备注吗？',
        enter_room_number: '输入您的房间号：',
        enter_table_code: '桌号：',
        incorrect_room_number: '房间号不正确',
        where_should_we_deliver: '我们应该在哪里送货？',
        my_room: '我的房间',
        your_email: '输入你的电子邮件：',
        email_expl: '此电子邮件将用于您的收据以及积分。',
        incorrect_email_format: '电子邮件格式不正确',
        your_full_name: '你的全名：',
        incorrect_name_format: '名称格式不正确',
        your_phone_number: '你的电话号码：',
        incorrect_phone_number_format: '电话号码格式不正确',
        your_total_short: '你的总计',
        please_ensure_paid_hotel:
            '此订单将记入您的房间。请确保在退房前结清账单。',
        please_ensure_paid_1: '请确保在离开场地之前支付您的订单。',
        please_ensure_paid:
            '请确保在离开场地之前支付您的订单。您可以通过再次扫描二维码并点击“当前桌账单”按钮，选择您要支付的项目来完成此操作。',
    },
    ar: {
        browseMenuOrder: 'تصفح القائمة واطلب مباشرة من هاتفك!',
        viewOurMenus: 'اضغط لعرض قوائمنا',
        ourMenus: 'قوائمنا',
        selectLanguage: 'اختر اللغة',
        payLaterTotal: 'فاتورة الطاولة الحالية:',
        notAcceptingOrdersPickup:
            'هذا المطعم لا يقبل طلبات الاستلام في الوقت الحالي.',
        notAcceptingOrdersDelivery:
            'هذا المطعم لا يقبل طلبات التوصيل في الوقت الحالي.',
        minimumOrderValue: 'الحد الأدنى لقيمة الطلبات للتوصيل:',
        notAcceptingOrders: 'هذا المطعم لا يقبل الطلبات في الوقت الحالي.',
        discountsAvailable: '% الخصومات المتاحة %',
        orderFeeMsg: 'يتم تحصيل رسوم على كل طلب',
        menuLanguage: 'لغة القائمة:',
        spend: 'أنفق',
        get: 'احصل',
        per_day: 'في اليوم',
        get_caps: 'احصل',
        select_gift_card_id: 'الرجاء اختيار بطاقة الهدايا التي ترغب في شرائها.',
        select_sub: 'الرجاء اختيار الاشتراك الذي ترغب في شرائه.',
        continue_delitepay_tapped: 'الاستمرار مع DelitePay',
        continue_deliteclub_tapped: 'الاستمرار مع DeliteClub',
        not_accepting_orders: 'هذا المطعم لا يقبل الطلبات في الوقت الحالي.',
        discount_tags_available: '% الخصومات المتاحة %',
        view_our_menus: 'اضغط لعرض قوائمنا',
        our_menus: 'قوائمنا',
        items_3: 'عناصر مجانية',
        specify_email: 'يرجى تحديد بريدك الإلكتروني.',
        select_language: 'اختر اللغة',
        select_menu: 'اختر القائمة',
        delivery: 'التوصيل',
        pickup: 'الاستلام',
        dine_in: 'تناول الطعام في الداخل',
        tap_to_start: 'اضغط للبدء',
        view_pickup_menu: 'عرض قائمة الاستلام',
        view_delivery_menu: 'عرض قائمة التوصيل',
        view_pickup_delivery_menus: 'عرض قوائم الاستلام / التوصيل',
        your_total_colon: 'مجموعك:',
        including_order_fee: '(تشمل رسوم الطلب)',
        place_order: 'تقديم الطلب',
        go_back: 'ارجع',
        room_number_needed: 'يرجى إدخال رقم الغرفة.',
        email_needed_hotel: 'يرجى إدخال بريدك الإلكتروني.',
        specify_name: 'يرجى ملء جميع الحقول.',
        order_confirmation: 'تأكيد الطلب',
        your_information: 'معلوماتك',
        any_allergies_short: 'أي حساسية أو ملاحظات؟',
        enter_room_number: 'أدخل رقم غرفتك:',
        enter_table_code: 'رمز الطاولة:',
        incorrect_room_number: 'رقم الغرفة غير صحيح',
        where_should_we_deliver: 'أين يجب أن نوصلك؟',
        my_room: 'غرفتي',
        your_email: 'أدخل بريدك الإلكتروني:',
        email_expl:
            'سيتم استخدام هذا البريد الإلكتروني لإيصالك وكذلك لنقاط المكافأة.',
        incorrect_email_format: 'تنسيق البريد الإلكتروني غير صحيح',
        your_full_name: 'اسمك الكامل:',
        incorrect_name_format: 'تنسيق الاسم غير صحيح',
        your_phone_number: 'رقم هاتفك:',
        incorrect_phone_number_format: 'تنسيق رقم الهاتف غير صحيح',
        your_total_short: 'مجموعك',
        please_ensure_paid_hotel:
            'سيتم تحصيل هذا الطلب من غرفتك. يرجى التأكد من تسوية الفاتورة مع الفندق قبل تسجيل الخروج.',
        please_ensure_paid_1: 'يرجى التأكد من دفع طلبك قبل مغادرة المكان.',
        please_ensure_paid:
            'يرجى التأكد من دفع طلبك قبل مغادرة المكان. يمكنك القيام بذلك عن طريق مسح رمز الاستجابة السريعة مرة أخرى والنقر على زر "فاتورة الطاولة الحالية" لاختيار العناصر التي ترغب في دفعها.',
    },
    ru: {
        browseMenuOrder: 'Просмотрите меню и закажите прямо с вашего телефона!',
        viewOurMenus: 'Нажмите, чтобы посмотреть наши меню',
        ourMenus: 'Наши Меню',
        selectLanguage: 'Выберите язык',
        payLaterTotal: 'Текущий счет:',
        notAcceptingOrdersPickup:
            'Этот ресторан в настоящее время не принимает заказы на самовывоз.',
        notAcceptingOrdersDelivery:
            'Этот ресторан в настоящее время не принимает заказы на доставку.',
        minimumOrderValue: 'Минимальная стоимость заказа для доставки:',
        notAcceptingOrders:
            'Этот ресторан в настоящее время не принимает заказы.',
        discountsAvailable: '% Доступные Скидки %',
        orderFeeMsg: 'За каждый заказ взимается плата',
        menuLanguage: 'Язык меню:',
        spend: 'Потратьте',
        get: 'Получите',
        per_day: 'в день',
        get_caps: 'Получите',
        select_gift_card_id:
            'Пожалуйста, выберите подарочную карту, которую хотите приобрести.',
        select_sub:
            'Пожалуйста, выберите подписку, которую вы хотите приобрести.',
        continue_delitepay_tapped: 'Продолжить с DelitePay',
        continue_deliteclub_tapped: 'Продолжить с DeliteClub',
        not_accepting_orders:
            'Этот ресторан в настоящее время не принимает заказы.',
        discount_tags_available: '% Доступные Скидки %',
        view_our_menus: 'Нажмите, чтобы посмотреть наши меню',
        our_menus: 'Наши Меню',
        items_3: 'бесплатные товары',
        specify_email: 'Пожалуйста, укажите ваш адрес электронной почты.',
        select_language: 'Выберите язык',
        select_menu: 'Выберите меню',
        delivery: 'Доставка',
        pickup: 'Самовывоз',
        dine_in: 'Ужин в ресторане',
        tap_to_start: 'Нажмите, чтобы начать',
        view_pickup_menu: 'Просмотреть меню самовывоза',
        view_delivery_menu: 'Просмотреть меню доставки',
        view_pickup_delivery_menus: 'Просмотреть меню самовывоза / доставки',
        your_total_colon: 'Ваш итог:',
        including_order_fee: '(Включая плату за заказ)',
        place_order: 'Оформить заказ',
        go_back: 'Назад',
        room_number_needed: 'Пожалуйста, введите номер комнаты.',
        email_needed_hotel: 'Пожалуйста, введите вашу электронную почту.',
        specify_name: 'Пожалуйста, заполните все поля.',
        order_confirmation: 'Подтверждение заказа',
        your_information: 'Ваша информация',
        any_allergies_short: 'Есть аллергии или примечания?',
        enter_room_number: 'Введите номер вашей комнаты:',
        enter_table_code: 'Код стола:',
        incorrect_room_number: 'Неверный номер комнаты',
        where_should_we_deliver: 'Куда доставить?',
        my_room: 'Моя комната',
        your_email: 'Введите вашу электронную почту:',
        email_expl:
            'Этот электронный адрес будет использоваться для вашей квитанции, а также для бонусных баллов.',
        incorrect_email_format: 'Неверный формат электронной почты',
        your_full_name: 'Ваше полное имя:',
        incorrect_name_format: 'Неверный формат имени',
        your_phone_number: 'Ваш номер телефона:',
        incorrect_phone_number_format: 'Неверный формат номера телефона',
        your_total_short: 'Ваш итог',
        please_ensure_paid_hotel:
            'Этот заказ будет добавлен в счет вашей комнаты. Пожалуйста, убедитесь, что вы оплатили счет в отеле перед выездом.',
        please_ensure_paid_1:
            'Пожалуйста, убедитесь, что вы оплатили заказ перед уходом.',
        please_ensure_paid:
            'Пожалуйста, убедитесь, что вы оплатили заказ перед уходом. Вы можете сделать это, снова отсканировав QR-код и нажав на кнопку "Текущий счет", чтобы выбрать элементы для оплаты.',
    },
    ja: {
        browseMenuOrder: 'メニューを確認し、電話から直接注文してください！',
        viewOurMenus: 'メニューを表示するにはタップしてください',
        ourMenus: '私たちのメニュー',
        selectLanguage: '言語を選択',
        payLaterTotal: '現在のテーブルの請求書:',
        notAcceptingOrdersPickup:
            'このレストランは現在、持ち帰り注文を受け付けていません。',
        notAcceptingOrdersDelivery:
            'このレストランは現在、配達注文を受け付けていません。',
        minimumOrderValue: '配達注文の最低注文額:',
        notAcceptingOrders: 'このレストランは現在、注文を受け付けていません。',
        discountsAvailable: '% 利用可能な割引 %',
        orderFeeMsg: '各注文には手数料がかかります',
        menuLanguage: 'メニューの言語:',
        spend: '支出',
        get: '取得',
        per_day: '1日あたり',
        get_caps: '取得',
        select_gift_card_id: '購入したいギフトカードを選択してください。',
        select_sub: '購入したいサブスクリプションを選択してください。',
        continue_delitepay_tapped: 'DelitePayを続行',
        continue_deliteclub_tapped: 'DeliteClubを続行',
        not_accepting_orders:
            'このレストランは現在、注文を受け付けていません。',
        discount_tags_available: '% 利用可能な割引 %',
        view_our_menus: 'メニューを表示するにはタップしてください',
        our_menus: '私たちのメニュー',
        items_3: '無料のアイテム',
        specify_email: 'メールアドレスを指定してください。',
        select_language: '言語を選択',
        select_menu: 'メニューを選択',
        delivery: '配達',
        pickup: '持ち帰り',
        dine_in: '店内飲食',
        tap_to_start: '開始するにはタップ',
        view_pickup_menu: '持ち帰りメニューを表示',
        view_delivery_menu: '配達メニューを表示',
        view_pickup_delivery_menus: '持ち帰り/配達メニューを表示',
        your_total_colon: '合計:',
        including_order_fee: '(注文料込み)',
        place_order: '注文する',
        go_back: '戻る',
        room_number_needed: '部屋番号を入力してください。',
        email_needed_hotel: 'メールアドレスを入力してください。',
        specify_name: 'すべての項目に入力してください。',
        order_confirmation: '注文確認',
        your_information: 'あなたの情報',
        any_allergies_short: 'アレルギーやメモはありますか？',
        enter_room_number: '部屋番号を入力してください:',
        enter_table_code: 'テーブルコード:',
        incorrect_room_number: '部屋番号が正しくありません',
        where_should_we_deliver: 'どこに届けましょうか？',
        my_room: '私の部屋',
        your_email: 'メールアドレスを入力してください:',
        email_expl: 'このメールは、領収書とボーナスポイントに使用されます。',
        incorrect_email_format: 'メールアドレスの形式が正しくありません',
        your_full_name: 'あなたのフルネーム:',
        incorrect_name_format: '名前の形式が正しくありません',
        your_phone_number: 'あなたの電話番号:',
        incorrect_phone_number_format: '電話番号の形式が正しくありません',
        your_total_short: '合計',
        please_ensure_paid_hotel:
            'この注文は部屋に請求されます。チェックアウト前にホテルで請求を清算してください。',
        please_ensure_paid_1: '出発前に注文をお支払いください。',
        please_ensure_paid:
            '出発前に注文をお支払いください。もう一度QRコードをスキャンし、「現在のテーブル請求」ボタンをクリックして、支払いたい項目を選択してください。',
    },
    ko: {
        browseMenuOrder: '메뉴를 보고 바로 핸드폰에서 주문하세요!',
        viewOurMenus: '우리의 메뉴를 보려면 누르세요',
        ourMenus: '우리의 메뉴',
        selectLanguage: '언어 선택',
        payLaterTotal: '현재 테이블 청구서:',
        notAcceptingOrdersPickup:
            '이 레스토랑은 현재 픽업 주문을 받고 있지 않습니다.',
        notAcceptingOrdersDelivery:
            '이 레스토랑은 현재 배달 주문을 받고 있지 않습니다.',
        minimumOrderValue: '배달 주문의 최소 주문 금액:',
        notAcceptingOrders: '이 레스토랑은 현재 주문을 받고 있지 않습니다.',
        discountsAvailable: '% 이용 가능한 할인 %',
        orderFeeMsg: '각 주문에는 수수료가 부과됩니다',
        menuLanguage: '메뉴 언어:',
        spend: '지출',
        get: '얻다',
        per_day: '하루에',
        get_caps: '얻다',
        select_gift_card_id: '구입할 기프트 카드를 선택하십시오.',
        select_sub: '구입할 구독을 선택하십시오.',
        continue_delitepay_tapped: 'DelitePay 계속하기',
        continue_deliteclub_tapped: 'DeliteClub 계속하기',
        not_accepting_orders: '이 레스토랑은 현재 주문을 받고 있지 않습니다.',
        discount_tags_available: '% 이용 가능한 할인 %',
        view_our_menus: '우리의 메뉴를 보려면 누르세요',
        our_menus: '우리의 메뉴',
        items_3: '무료 품목',
        specify_email: '이메일을 지정하십시오.',
        select_language: '언어 선택',
        select_menu: '메뉴 선택',
        delivery: '배달',
        pickup: '픽업',
        dine_in: '식당 내 식사',
        tap_to_start: '시작하려면 탭하세요',
        view_pickup_menu: '픽업 메뉴 보기',
        view_delivery_menu: '배달 메뉴 보기',
        view_pickup_delivery_menus: '픽업 / 배달 메뉴 보기',
        your_total_colon: '총액:',
        including_order_fee: '(주문 수수료 포함)',
        place_order: '주문하기',
        go_back: '돌아가기',
        room_number_needed: '방 번호를 입력해주세요.',
        email_needed_hotel: '이메일을 입력해주세요.',
        specify_name: '모든 항목을 작성해주세요.',
        order_confirmation: '주문 확인',
        your_information: '당신의 정보',
        any_allergies_short: '알레르기 또는 비고가 있습니까?',
        enter_room_number: '방 번호를 입력하세요:',
        enter_table_code: '테이블 코드:',
        incorrect_room_number: '잘못된 방 번호',
        where_should_we_deliver: '어디에 배달할까요?',
        my_room: '내 방',
        your_email: '이메일을 입력하세요:',
        email_expl: '이 이메일은 영수증 및 보너스 포인트에 사용됩니다.',
        incorrect_email_format: '이메일 형식이 잘못되었습니다',
        your_full_name: '당신의 성명:',
        incorrect_name_format: '이름 형식이 잘못되었습니다',
        your_phone_number: '당신의 전화번호:',
        incorrect_phone_number_format: '전화번호 형식이 잘못되었습니다',
        your_total_short: '총액',
        please_ensure_paid_hotel:
            '이 주문은 방에 청구됩니다. 체크아웃하기 전에 호텔에서 청구서를 결제해주세요.',
        please_ensure_paid_1: '장소를 떠나기 전에 주문을 결제해주세요.',
        please_ensure_paid:
            '장소를 떠나기 전에 주문을 결제해주세요. QR 코드를 다시 스캔하고 "현재 테이블 청구서" 버튼을 클릭하여 결제할 항목을 선택할 수 있습니다.',
    },
    de: {
        browseMenuOrder:
            'Durchsuchen Sie das Menü und bestellen Sie direkt von Ihrem Handy!',
        viewOurMenus: 'Tippen Sie, um unsere Menüs anzuzeigen',
        ourMenus: 'Unsere Menüs',
        selectLanguage: 'Sprache auswählen',
        payLaterTotal: 'Aktuelle Rechnung:',
        notAcceptingOrdersPickup:
            'Dieses Restaurant nimmt derzeit keine Bestellungen zur Abholung entgegen.',
        notAcceptingOrdersDelivery:
            'Dieses Restaurant nimmt derzeit keine Lieferaufträge entgegen.',
        minimumOrderValue: 'Mindestbestellwert für Lieferaufträge:',
        notAcceptingOrders:
            'Dieses Restaurant nimmt derzeit keine Bestellungen an.',
        discountsAvailable: '% Verfügbare Rabatte %',
        orderFeeMsg: 'Für jede Bestellung wird eine Gebühr erhoben',
        menuLanguage: 'Menüsprache:',
        spend: 'Ausgeben',
        get: 'Erhalten',
        per_day: 'pro Tag',
        get_caps: 'Erhalten',
        select_gift_card_id:
            'Bitte wählen Sie eine Geschenkkarte, die Sie kaufen möchten.',
        select_sub: 'Bitte wählen Sie ein Abonnement, das Sie kaufen möchten.',
        continue_delitepay_tapped: 'Weiter mit DelitePay',
        continue_deliteclub_tapped: 'Weiter mit DeliteClub',
        not_accepting_orders:
            'Dieses Restaurant nimmt derzeit keine Bestellungen an.',
        discount_tags_available: '% Verfügbare Rabatte %',
        view_our_menus: 'Tippen Sie, um unsere Menüs anzuzeigen',
        our_menus: 'Unsere Menüs',
        items_3: 'kostenlose Artikel',
        specify_email: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
        select_language: 'Sprache auswählen',
        select_menu: 'Menü auswählen',
        delivery: 'Lieferung',
        pickup: 'Abholung',
        dine_in: 'Vor Ort essen',
        tap_to_start: 'Tippen Sie zum Starten',
        view_pickup_menu: 'Abholmenü anzeigen',
        view_delivery_menu: 'Liefermenü anzeigen',
        view_pickup_delivery_menus: 'Abhol- / Liefermenüs anzeigen',
        your_total_colon: 'Ihr Gesamtbetrag:',
        including_order_fee: '(Inklusive Bestellgebühr)',
        place_order: 'Bestellung aufgeben',
        go_back: 'Zurück',
        room_number_needed: 'Bitte geben Sie die Zimmernummer ein.',
        email_needed_hotel: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
        specify_name: 'Bitte füllen Sie alle Felder aus.',
        order_confirmation: 'Bestellbestätigung',
        your_information: 'Ihre Informationen',
        any_allergies_short: 'Irgendwelche Allergien oder Anmerkungen?',
        enter_room_number: 'Geben Sie Ihre Zimmernummer ein:',
        enter_table_code: 'Tischcode:',
        incorrect_room_number: 'Falsche Zimmernummer',
        where_should_we_deliver: 'Wohin sollen wir liefern?',
        my_room: 'Mein Zimmer',
        your_email: 'Geben Sie Ihre E-Mail-Adresse ein:',
        email_expl:
            'Diese E-Mail wird für Ihre Quittung sowie für Bonuspunkte verwendet.',
        incorrect_email_format: 'Falsches E-Mail-Format',
        your_full_name: 'Ihr vollständiger Name:',
        incorrect_name_format: 'Falsches Namensformat',
        your_phone_number: 'Ihre Telefonnummer:',
        incorrect_phone_number_format: 'Falsches Telefonnummernformat',
        your_total_short: 'Ihr Gesamtbetrag',
        please_ensure_paid_hotel:
            'Diese Bestellung wird auf Ihr Zimmerkonto gebucht. Bitte stellen Sie sicher, dass Sie die Rechnung vor dem Check-out im Hotel begleichen.',
        please_ensure_paid_1:
            'Bitte stellen Sie sicher, dass Sie Ihre Bestellung vor Verlassen des Veranstaltungsortes bezahlen.',
        please_ensure_paid:
            'Bitte stellen Sie sicher, dass Sie Ihre Bestellung vor Verlassen des Veranstaltungsortes bezahlen. Sie können dies tun, indem Sie den QR-Code erneut scannen und auf die Schaltfläche "Aktuelle Tischrechnung" klicken, um die Artikel auszuwählen, die Sie bezahlen möchten.',
    },
    fr: {
        browseMenuOrder:
            'Parcourez le menu et commandez directement depuis votre téléphone!',
        viewOurMenus: 'Appuyez pour voir nos menus',
        ourMenus: 'Nos Menus',
        selectLanguage: 'Choisir la langue',
        payLaterTotal: 'Facture actuelle de la table:',
        notAcceptingOrdersPickup:
            "Ce restaurant n'accepte pas les commandes à emporter pour le moment.",
        notAcceptingOrdersDelivery:
            "Ce restaurant n'accepte pas les commandes de livraison pour le moment.",
        minimumOrderValue:
            'Valeur minimale de commande pour les commandes de livraison:',
        notAcceptingOrders:
            "Ce restaurant n'accepte pas de commandes pour le moment.",
        discountsAvailable: '% Réductions disponibles %',
        orderFeeMsg: 'Chaque commande entraîne des frais',
        menuLanguage: 'Langue du menu:',
        spend: 'Dépenser',
        get: 'Obtenir',
        per_day: 'par jour',
        get_caps: 'Obtenir',
        select_gift_card_id:
            'Veuillez sélectionner une carte-cadeau que vous souhaitez acheter.',
        select_sub:
            'Veuillez sélectionner un abonnement que vous souhaitez acheter.',
        continue_delitepay_tapped: 'Continuer avec DelitePay',
        continue_deliteclub_tapped: 'Continuer avec DeliteClub',
        not_accepting_orders:
            "Ce restaurant n'accepte pas de commandes pour le moment.",
        discount_tags_available: '% Réductions disponibles %',
        view_our_menus: 'Appuyez pour voir nos menus',
        our_menus: 'Nos Menus',
        items_3: 'articles gratuits',
        specify_email: 'Veuillez préciser votre email.',
        select_language: 'Choisir la langue',
        select_menu: 'Choisir le menu',
        delivery: 'Livraison',
        pickup: 'À emporter',
        dine_in: 'Dîner sur place',
        tap_to_start: 'Appuyez pour démarrer',
        view_pickup_menu: "Voir le menu d'à emporter",
        view_delivery_menu: 'Voir le menu de livraison',
        view_pickup_delivery_menus: 'Voir les menus à emporter / livraison',
        your_total_colon: 'Votre Total:',
        including_order_fee: '(Incl. Frais de commande)',
        place_order: 'Passer la commande',
        go_back: 'Retourner',
        room_number_needed: 'Veuillez entrer le numéro de la chambre.',
        email_needed_hotel: 'Veuillez entrer votre email.',
        specify_name: 'Veuillez remplir tous les champs.',
        order_confirmation: 'Confirmation de commande',
        your_information: 'Vos Informations',
        any_allergies_short: 'Des allergies ou des remarques?',
        enter_room_number: 'Entrez votre numéro de chambre:',
        enter_table_code: 'Code de table:',
        incorrect_room_number: 'Numéro de chambre incorrect',
        where_should_we_deliver: 'Où devons-nous livrer?',
        my_room: 'Ma chambre',
        your_email: 'Entrez votre email:',
        email_expl:
            'Cet email sera utilisé pour votre reçu ainsi que pour les points bonus.',
        incorrect_email_format: 'Format d’email incorrect',
        your_full_name: 'Votre nom complet:',
        incorrect_name_format: 'Format de nom incorrect',
        your_phone_number: 'Votre numéro de téléphone:',
        incorrect_phone_number_format:
            'Format de numéro de téléphone incorrect',
        your_total_short: 'Votre Total',
        please_ensure_paid_hotel:
            'Cette commande sera facturée à votre chambre. Veuillez vous assurer de régler la facture avec l’hôtel avant de quitter.',
        please_ensure_paid_1:
            'Veuillez vous assurer de payer votre commande avant de quitter les lieux.',
        please_ensure_paid:
            'Veuillez vous assurer de payer votre commande avant de quitter les lieux. Vous pouvez le faire en scannant à nouveau le code QR et en cliquant sur le bouton "Facture actuelle de la table" pour sélectionner les articles que vous souhaitez payer.',
    },
    pl: {
        browseMenuOrder:
            'Przejrzyj menu i zamów bezpośrednio ze swojego telefonu!',
        viewOurMenus: 'Dotknij, aby zobaczyć nasze menu',
        ourMenus: 'Nasze Menu',
        selectLanguage: 'Wybierz język',
        payLaterTotal: 'Obecny rachunek za stolik:',
        notAcceptingOrdersPickup:
            'Ta restauracja obecnie nie przyjmuje zamówień na odbiór.',
        notAcceptingOrdersDelivery:
            'Ta restauracja obecnie nie przyjmuje zamówień na dostawę.',
        minimumOrderValue: 'Minimalna wartość zamówienia dla dostawy:',
        notAcceptingOrders: 'Ta restauracja obecnie nie przyjmuje zamówień.',
        discountsAvailable: '% Dostępne Rabaty %',
        orderFeeMsg: 'Każde zamówienie podlega opłacie',
        menuLanguage: 'Język menu:',
        spend: 'Wydać',
        get: 'Dostać',
        per_day: 'na dzień',
        get_caps: 'Dostać',
        select_gift_card_id:
            'Proszę wybrać kartę podarunkową, którą chcesz kupić.',
        select_sub: 'Proszę wybrać subskrypcję, którą chcesz kupić.',
        continue_delitepay_tapped: 'Kontynuuj z DelitePay',
        continue_deliteclub_tapped: 'Kontynuuj z DeliteClub',
        not_accepting_orders: 'Ta restauracja obecnie nie przyjmuje zamówień.',
        discount_tags_available: '% Dostępne Rabaty %',
        view_our_menus: 'Dotknij, aby zobaczyć nasze menu',
        our_menus: 'Nasze Menu',
        items_3: 'bezpłatne przedmioty',
        specify_email: 'Proszę podać swój adres e-mail.',
        select_language: 'Wybierz język',
        select_menu: 'Wybierz menu',
        delivery: 'Dostawa',
        pickup: 'Odbiór',
        dine_in: 'Jedzenie na miejscu',
        tap_to_start: 'Dotknij, aby rozpocząć',
        view_pickup_menu: 'Zobacz menu odbioru',
        view_delivery_menu: 'Zobacz menu dostawy',
        view_pickup_delivery_menus: 'Zobacz menu odbioru / dostawy',
        your_total_colon: 'Twój Całkowity:',
        including_order_fee: '(W tym opłata za zamówienie)',
        place_order: 'Złóż zamówienie',
        go_back: 'Wróć',
        room_number_needed: 'Proszę podać numer pokoju.',
        email_needed_hotel: 'Proszę podać swój adres e-mail.',
        specify_name: 'Proszę wypełnić wszystkie pola.',
        order_confirmation: 'Potwierdzenie zamówienia',
        your_information: 'Twoje informacje',
        any_allergies_short: 'Jakiekolwiek alergie lub uwagi?',
        enter_room_number: 'Podaj numer swojego pokoju:',
        enter_table_code: 'Kod stołu:',
        incorrect_room_number: 'Nieprawidłowy numer pokoju',
        where_should_we_deliver: 'Gdzie mamy dostarczyć?',
        my_room: 'Mój pokój',
        your_email: 'Podaj swój adres e-mail:',
        email_expl:
            'Ten e-mail zostanie wykorzystany do Twojego paragonu oraz punktów bonusowych.',
        incorrect_email_format: 'Nieprawidłowy format adresu e-mail',
        your_full_name: 'Twoje pełne imię:',
        incorrect_name_format: 'Nieprawidłowy format imienia',
        your_phone_number: 'Twój numer telefonu:',
        incorrect_phone_number_format: 'Nieprawidłowy format numeru telefonu',
        your_total_short: 'Twój Całkowity',
        please_ensure_paid_hotel:
            'To zamówienie zostanie doliczone do Twojego pokoju. Proszę upewnij się, że uregulowałeś rachunek z hotelem przed wymeldowaniem.',
        please_ensure_paid_1:
            'Proszę upewnij się, że zapłaciłeś za zamówienie przed opuszczeniem lokalu.',
        please_ensure_paid:
            'Proszę upewnij się, że zapłaciłeś za zamówienie przed opuszczeniem lokalu. Możesz to zrobić, ponownie skanując kod QR i klikając przycisk "Aktualny rachunek stołu", aby wybrać przedmioty, za które chcesz zapłacić.',
    },
    pt: {
        browseMenuOrder: 'Verifique o menu e peça diretamente do seu telefone!',
        viewOurMenus: 'Toque para ver nossos menus',
        ourMenus: 'Nossos Menus',
        selectLanguage: 'Selecionar idioma',
        payLaterTotal: 'Conta atual da mesa:',
        notAcceptingOrdersPickup:
            'Este restaurante não está aceitando pedidos para retirada no momento.',
        notAcceptingOrdersDelivery:
            'Este restaurante não está aceitando pedidos de entrega no momento.',
        minimumOrderValue: 'Valor mínimo do pedido para entregas:',
        notAcceptingOrders:
            'Este restaurante não está aceitando pedidos no momento.',
        discountsAvailable: '% Descontos Disponíveis %',
        orderFeeMsg: 'Cada pedido está sujeito a uma taxa',
        menuLanguage: 'Idioma do menu:',
        spend: 'Gastar',
        get: 'Obter',
        per_day: 'por dia',
        get_caps: 'Obter',
        select_gift_card_id:
            'Selecione um cartão-presente que você deseja comprar.',
        select_sub: 'Selecione uma assinatura que você deseja comprar.',
        continue_delitepay_tapped: 'Continuar com DelitePay',
        continue_deliteclub_tapped: 'Continuar com DeliteClub',
        not_accepting_orders:
            'Este restaurante não está aceitando pedidos no momento.',
        discount_tags_available: '% Descontos Disponíveis %',
        view_our_menus: 'Toque para ver nossos menus',
        our_menus: 'Nossos Menus',
        items_3: 'itens grátis',
        specify_email: 'Por favor, especifique seu e-mail.',
        select_language: 'Selecionar idioma',
        select_menu: 'Selecionar Menu',
        delivery: 'Entrega',
        pickup: 'Retirada',
        dine_in: 'Jantar no local',
        tap_to_start: 'Toque para começar',
        view_pickup_menu: 'Ver menu de retirada',
        view_delivery_menu: 'Ver menu de entrega',
        view_pickup_delivery_menus: 'Ver menus de retirada/entrega',
        your_total_colon: 'Seu Total:',
        including_order_fee: '(Incl. Taxa de Pedido)',
        place_order: 'Fazer Pedido',
        go_back: 'Voltar',
        room_number_needed: 'Por favor, insira o número do quarto.',
        email_needed_hotel: 'Por favor, insira seu e-mail.',
        specify_name: 'Por favor, preencha todos os campos.',
        order_confirmation: 'Confirmação de Pedido',
        your_information: 'Suas Informações',
        any_allergies_short: 'Alguma alergia ou nota?',
        enter_room_number: 'Insira o número do seu quarto:',
        enter_table_code: 'Código da Mesa:',
        incorrect_room_number: 'Número do quarto incorreto',
        where_should_we_deliver: 'Onde devemos entregar?',
        my_room: 'Meu quarto',
        your_email: 'Digite seu e-mail:',
        email_expl:
            'Este e-mail será usado para o seu recibo, bem como para pontos de bônus.',
        incorrect_email_format: 'Formato de e-mail incorreto',
        your_full_name: 'Seu Nome Completo:',
        incorrect_name_format: 'Formato de nome incorreto',
        your_phone_number: 'Seu número de telefone:',
        incorrect_phone_number_format:
            'Formato de número de telefone incorreto',
        your_total_short: 'Seu Total',
        please_ensure_paid_hotel:
            'Este pedido será cobrado no seu quarto. Certifique-se de pagar a conta com o hotel antes de fazer o check-out.',
        please_ensure_paid_1:
            'Certifique-se de pagar pelo seu pedido antes de sair do local.',
        please_ensure_paid:
            'Certifique-se de pagar pelo seu pedido antes de sair do local. Você pode fazer isso escaneando o código QR novamente e clicando no botão "Conta Atual da Mesa" para selecionar os itens que deseja pagar.',
    },
    it: {
        browseMenuOrder:
            'Controlla il menu e ordina direttamente dal tuo telefono!',
        viewOurMenus: 'Tocca per vedere i nostri menu',
        ourMenus: 'I nostri menu',
        selectLanguage: 'Seleziona la lingua',
        payLaterTotal: 'Conto attuale del tavolo:',
        notAcceptingOrdersPickup:
            'Questo ristorante al momento non accetta ordini per il ritiro.',
        notAcceptingOrdersDelivery:
            'Questo ristorante al momento non accetta ordini per la consegna.',
        minimumOrderValue: "Valore minimo dell'ordine per le consegne:",
        notAcceptingOrders: 'Questo ristorante al momento non accetta ordini.',
        discountsAvailable: '% Sconti Disponibili %',
        orderFeeMsg: 'Ogni ordine è soggetto a una tassa',
        menuLanguage: 'Lingua del menu:',
        spend: 'Spendere',
        get: 'Ottenere',
        per_day: 'al giorno',
        get_caps: 'Ottenere',
        select_gift_card_id:
            'Si prega di selezionare una carta regalo che si desidera acquistare.',
        select_sub:
            'Si prega di selezionare un abbonamento che si desidera acquistare.',
        continue_delitepay_tapped: 'Continua con DelitePay',
        continue_deliteclub_tapped: 'Continua con DeliteClub',
        not_accepting_orders:
            'Questo ristorante al momento non accetta ordini.',
        discount_tags_available: '% Sconti Disponibili %',
        view_our_menus: 'Tocca per vedere i nostri menu',
        our_menus: 'I nostri menu',
        items_3: 'articoli gratuiti',
        specify_email: 'Si prega di specificare la tua email.',
        select_language: 'Seleziona la lingua',
        select_menu: 'Seleziona il menu',
        delivery: 'Consegna',
        pickup: 'Ritiro',
        dine_in: 'Pranzo al ristorante',
        tap_to_start: 'Tocca per iniziare',
        view_pickup_menu: 'Visualizza il menu per il ritiro',
        view_delivery_menu: 'Visualizza il menu per la consegna',
        view_pickup_delivery_menus: 'Visualizza menu ritiro / consegna',
        your_total_colon: 'Il tuo totale:',
        including_order_fee: '(Incl. Tassa di Ordine)',
        place_order: 'Effettua ordine',
        go_back: 'Torna indietro',
        room_number_needed: 'Si prega di inserire il numero della stanza.',
        email_needed_hotel: 'Si prega di inserire la tua email.',
        specify_name: 'Si prega di compilare tutti i campi.',
        order_confirmation: 'Conferma Ordine',
        your_information: 'Le tue informazioni',
        any_allergies_short: 'Allergie o note?',
        enter_room_number: 'Inserisci il numero della tua stanza:',
        enter_table_code: 'Codice del tavolo:',
        incorrect_room_number: 'Numero della stanza errato',
        where_should_we_deliver: 'Dove dovremmo consegnare?',
        my_room: 'La mia stanza',
        your_email: 'Inserisci la tua email:',
        email_expl:
            'Questa email verrà utilizzata per la ricevuta e per i punti bonus.',
        incorrect_email_format: 'Formato email errato',
        your_full_name: 'Il tuo nome completo:',
        incorrect_name_format: 'Formato del nome errato',
        your_phone_number: 'Il tuo numero di telefono:',
        incorrect_phone_number_format: 'Formato numero di telefono errato',
        your_total_short: 'Il tuo totale',
        please_ensure_paid_hotel:
            'Questo ordine verrà addebitato sulla tua stanza. Assicurati di saldare il conto con l’hotel prima del check-out.',
        please_ensure_paid_1:
            'Assicurati di pagare il tuo ordine prima di lasciare il luogo.',
        please_ensure_paid:
            'Assicurati di pagare il tuo ordine prima di lasciare il luogo. Puoi farlo scansionando di nuovo il codice QR e cliccando sul pulsante "Conto Attuale del Tavolo" per selezionare gli articoli che desideri pagare.',
    },
    nl: {
        browseMenuOrder: 'Bekijk het menu en bestel direct vanaf je telefoon!',
        viewOurMenus: "Tik om onze menu's te bekijken",
        ourMenus: "Onze Menu's",
        selectLanguage: 'Taal selecteren',
        payLaterTotal: 'Huidige tafelfactuur:',
        notAcceptingOrdersPickup:
            'Dit restaurant accepteert momenteel geen afhaalbestellingen.',
        notAcceptingOrdersDelivery:
            'Dit restaurant accepteert momenteel geen bezorgbestellingen.',
        minimumOrderValue: 'Minimale bestelwaarde voor bezorging:',
        notAcceptingOrders:
            'Dit restaurant accepteert momenteel geen bestellingen.',
        discountsAvailable: '% Beschikbare Kortingen %',
        orderFeeMsg:
            'Voor elke bestelling wordt een toeslag in rekening gebracht',
        menuLanguage: 'Menutaal:',
        spend: 'Besteden',
        get: 'Krijgen',
        per_day: 'per dag',
        get_caps: 'Krijgen',
        select_gift_card_id: 'Selecteer een cadeaukaart die u wilt kopen.',
        select_sub: 'Selecteer een abonnement dat u wilt kopen.',
        continue_delitepay_tapped: 'Doorgaan met DelitePay',
        continue_deliteclub_tapped: 'Doorgaan met DeliteClub',
        not_accepting_orders:
            'Dit restaurant accepteert momenteel geen bestellingen.',
        discount_tags_available: '% Beschikbare Kortingen %',
        view_our_menus: "Tik om onze menu's te bekijken",
        our_menus: "Onze Menu's",
        items_3: 'gratis items',
        specify_email: 'Geef uw e-mailadres op.',
        select_language: 'Taal selecteren',
        select_menu: 'Selecteer Menu',
        delivery: 'Bezorging',
        pickup: 'Afhalen',
        dine_in: 'Ter plaatse dineren',
        tap_to_start: 'Tik om te starten',
        view_pickup_menu: 'Bekijk afhaalmenu',
        view_delivery_menu: 'Bekijk bezorgmenu',
        view_pickup_delivery_menus: "Bekijk afhaal- / bezorgmenu's",
        your_total_colon: 'Uw totaal:',
        including_order_fee: '(Inclusief bestelkosten)',
        place_order: 'Bestelling plaatsen',
        go_back: 'Ga terug',
        room_number_needed: 'Vul het kamernummer in.',
        email_needed_hotel: 'Voer uw e-mailadres in.',
        specify_name: 'Vul alle velden in.',
        order_confirmation: 'Bestelbevestiging',
        your_information: 'Jouw informatie',
        any_allergies_short: 'Allergieën of opmerkingen?',
        enter_room_number: 'Vul uw kamernummer in:',
        enter_table_code: 'Tafelcode:',
        incorrect_room_number: 'Onjuist kamernummer',
        where_should_we_deliver: 'Waar moeten we bezorgen?',
        my_room: 'Mijn kamer',
        your_email: 'Voer uw e-mailadres in:',
        email_expl:
            'Dit e-mailadres wordt gebruikt voor uw bon en voor bonuspunten.',
        incorrect_email_format: 'Onjuist e-mailformaat',
        your_full_name: 'Uw volledige naam:',
        incorrect_name_format: 'Onjuist naamformaat',
        your_phone_number: 'Uw telefoonnummer:',
        incorrect_phone_number_format: 'Onjuist telefoonnummerformaat',
        your_total_short: 'Uw totaal',
        please_ensure_paid_hotel:
            'Deze bestelling wordt in rekening gebracht op uw kamer. Zorg ervoor dat u de rekening met het hotel afrekent voordat u uitcheckt.',
        please_ensure_paid_1:
            'Zorg ervoor dat u uw bestelling betaalt voordat u het terrein verlaat.',
        please_ensure_paid:
            'Zorg ervoor dat u uw bestelling betaalt voordat u het terrein verlaat. U kunt dit doen door de QR-code opnieuw te scannen en op de knop "Huidige tafelrekening" te klikken om de items te selecteren die u wilt betalen.',
    },
    hi: {
        browseMenuOrder: 'मेनू ब्राउज़ करें और सीधे अपने फोन से ऑर्डर करें!',
        viewOurMenus: 'हमारे मेनू देखने के लिए टैप करें',
        ourMenus: 'हमारे मेनू',
        selectLanguage: 'भाषा चुनें',
        payLaterTotal: 'वर्तमान तालिका बिल:',
        notAcceptingOrdersPickup:
            'यह रेस्टोरेंट वर्तमान में पिकअप ऑर्डर नहीं ले रहा है।',
        notAcceptingOrdersDelivery:
            'यह रेस्टोरेंट वर्तमान में डिलीवरी ऑर्डर नहीं ले रहा है।',
        minimumOrderValue: 'डिलीवरी ऑर्डर के लिए न्यूनतम ऑर्डर मूल्य:',
        notAcceptingOrders: 'यह रेस्टोरेंट वर्तमान में ऑर्डर नहीं ले रहा है।',
        discountsAvailable: '% उपलब्ध छूट %',
        orderFeeMsg: 'प्रत्येक ऑर्डर के लिए एक शुल्क लगाया जाता है',
        menuLanguage: 'मेनू भाषा:',
        spend: 'खर्च करना',
        get: 'प्राप्त करें',
        per_day: 'प्रति दिन',
        get_caps: 'प्राप्त करें',
        select_gift_card_id:
            'कृपया वह उपहार कार्ड चुनें जिसे आप खरीदना चाहते हैं।',
        select_sub: 'कृपया वह सदस्यता चुनें जिसे आप खरीदना चाहते हैं।',
        continue_delitepay_tapped: 'DelitePay के साथ जारी रखें',
        continue_deliteclub_tapped: 'DeliteClub के साथ जारी रखें',
        not_accepting_orders: 'यह रेस्टोरेंट वर्तमान में ऑर्डर नहीं ले रहा है।',
        discount_tags_available: '% उपलब्ध छूट %',
        view_our_menus: 'हमारे मेनू देखने के लिए टैप करें',
        our_menus: 'हमारे मेनू',
        items_3: 'मुफ्त आइटम',
        specify_email: 'कृपया अपना ईमेल निर्दिष्ट करें।',
        select_language: 'भाषा चुनें',
        select_menu: 'मेनू चुनें',
        delivery: 'डिलीवरी',
        pickup: 'पिकअप',
        dine_in: 'डाइन-इन',
        tap_to_start: 'प्रारंभ करने के लिए टैप करें',
        view_pickup_menu: 'पिकअप मेनू देखें',
        view_delivery_menu: 'डिलीवरी मेनू देखें',
        view_pickup_delivery_menus: 'पिकअप/डिलीवरी मेनू देखें',
        your_total_colon: 'आपका कुल:',
        including_order_fee: '(आदेश शुल्क सहित)',
        place_order: 'ऑर्डर करें',
        go_back: 'वापस जाएं',
        room_number_needed: 'कृपया कक्ष संख्या दर्ज करें।',
        email_needed_hotel: 'कृपया अपना ईमेल दर्ज करें।',
        specify_name: 'कृपया सभी फ़ील्ड भरें।',
        order_confirmation: 'ऑर्डर कन्फर्मेशन',
        your_information: 'आपकी जानकारी',
        any_allergies_short: 'कोई एलर्जी या नोट्स?',
        enter_room_number: 'अपना कक्ष संख्या दर्ज करें:',
        enter_table_code: 'टेबल कोड:',
        incorrect_room_number: 'गलत कक्ष संख्या',
        where_should_we_deliver: 'हम कहां डिलीवर करें?',
        my_room: 'मेरा कक्ष',
        your_email: 'अपना ईमेल दर्ज करें:',
        email_expl:
            'यह ईमेल आपकी रसीद के साथ-साथ बोनस पॉइंट्स के लिए उपयोग किया जाएगा।',
        incorrect_email_format: 'ईमेल प्रारूप गलत है',
        your_full_name: 'आपका पूरा नाम:',
        incorrect_name_format: 'नाम का प्रारूप गलत है',
        your_phone_number: 'आपका फोन नंबर:',
        incorrect_phone_number_format: 'फोन नंबर प्रारूप गलत है',
        your_total_short: 'आपका कुल',
        please_ensure_paid_hotel:
            'इस ऑर्डर का शुल्क आपके कक्ष में जोड़ा जाएगा। चेक-आउट करने से पहले कृपया होटल से बिल का निपटान करें।',
        please_ensure_paid_1:
            'कृपया स्थल छोड़ने से पहले अपने ऑर्डर का भुगतान करें।',
        please_ensure_paid:
            'कृपया स्थल छोड़ने से पहले अपने ऑर्डर का भुगतान करें। आप ऐसा क्यूआर कोड को फिर से स्कैन करके और "वर्तमान तालिका बिल" बटन पर क्लिक करके कर सकते हैं, जिससे आप उन वस्तुओं को चुन सकते हैं जिनका भुगतान करना चाहते हैं।',
    },
};

export default {
    en: {
        current_items: 'Current Orders',
        select_items_to_pay_for: 'Please tap on the items you wish to pay for.',
        your_total_colon: 'Your Total:',
        including_fee: '(Incl. Order Fee)',
        cover_the_whole_bill: 'Cover The Whole Bill',
        pay_now: 'Pay Now',
        dismiss: 'Dismiss',
    },
    es: {
        current_items: 'Órdenes Actuales',
        select_items_to_pay_for:
            'Por favor, toque los artículos que desea pagar.',
        your_total_colon: 'Tu Total:',
        including_fee: '(Incl. Tarifa de Pedido)',
        cover_the_whole_bill: 'Cubrir Toda La Cuenta',
        pay_now: 'Pagar Ahora',
        dismiss: 'Descartar',
    },
    zh: {
        current_items: '当前订单',
        select_items_to_pay_for: '请选择您要支付的商品。',
        your_total_colon: '你的总计:',
        including_fee: '(包括订单费用)',
        cover_the_whole_bill: '支付全部账单',
        pay_now: '立即支付',
        dismiss: '解雇',
    },
    ar: {
        current_items: 'الطلبات الحالية',
        select_items_to_pay_for:
            'يرجى النقر على العناصر التي ترغب في دفع ثمنها.',
        your_total_colon: 'مجموعك:',
        including_fee: '(يشمل رسوم الطلب)',
        cover_the_whole_bill: 'تغطية الفاتورة الكاملة',
        pay_now: 'ادفع الآن',
        dismiss: 'رفض',
    },
    ru: {
        current_items: 'Текущие заказы',
        select_items_to_pay_for: 'Пожалуйста, выберите товары для оплаты.',
        your_total_colon: 'Ваш итог:',
        including_fee: '(Включая плату за заказ)',
        cover_the_whole_bill: 'Оплатить весь счет',
        pay_now: 'Заплатить сейчас',
        dismiss: 'Отклонить',
    },
    ja: {
        current_items: '現在の注文',
        select_items_to_pay_for: 'お支払い希望のアイテムをタップしてください。',
        your_total_colon: '合計:',
        including_fee: '(注文料込み)',
        cover_the_whole_bill: '全ての請求をカバーする',
        pay_now: '今払う',
        dismiss: '却下',
    },
    ko: {
        current_items: '현재 주문',
        select_items_to_pay_for: '지불하려는 항목을 눌러주세요.',
        your_total_colon: '총액:',
        including_fee: '(주문 수수료 포함)',
        cover_the_whole_bill: '전체 청구서 커버',
        pay_now: '지금 결제',
        dismiss: '해고하다',
    },
    de: {
        current_items: 'Aktuelle Bestellungen',
        select_items_to_pay_for:
            'Bitte tippen Sie auf die Artikel, die Sie bezahlen möchten.',
        your_total_colon: 'Ihr Gesamtbetrag:',
        including_fee: '(Inklusive Bestellgebühr)',
        cover_the_whole_bill: 'Die gesamte Rechnung decken',
        pay_now: 'Jetzt bezahlen',
        dismiss: 'Ablehnen',
    },
    fr: {
        current_items: 'Commandes en cours',
        select_items_to_pay_for:
            'Veuillez appuyer sur les articles que vous souhaitez payer.',
        your_total_colon: 'Votre Total:',
        including_fee: '(Incl. Frais de commande)',
        cover_the_whole_bill: 'Couvrir toute la facture',
        pay_now: 'Payer maintenant',
        dismiss: 'Rejeter',
    },
    pl: {
        current_items: 'Aktualne zamówienia',
        select_items_to_pay_for:
            'Dotknij przedmiotów, za które chcesz zapłacić.',
        your_total_colon: 'Twój Całkowity:',
        including_fee: '(W tym opłata za zamówienie)',
        cover_the_whole_bill: 'Pokryj cały rachunek',
        pay_now: 'Zapłać teraz',
        dismiss: 'Odrzuć',
    },
    pt: {
        current_items: 'Pedidos atuais',
        select_items_to_pay_for: 'Toque nos itens que deseja pagar.',
        your_total_colon: 'Seu Total:',
        including_fee: '(Incl. Taxa de Pedido)',
        cover_the_whole_bill: 'Cobrir toda a conta',
        pay_now: 'Pague agora',
        dismiss: 'Descartar',
    },
    it: {
        current_items: 'Ordini correnti',
        select_items_to_pay_for: 'Tocca gli articoli che desideri pagare.',
        your_total_colon: 'Il tuo totale:',
        including_fee: '(Incl. Tassa di Ordine)',
        cover_the_whole_bill: 'Copri tutto il conto',
        pay_now: 'Paga ora',
        dismiss: 'Respingi',
    },
    nl: {
        current_items: 'Huidige bestellingen',
        select_items_to_pay_for: 'Tik op de items die je wilt betalen.',
        your_total_colon: 'Uw totaal:',
        including_fee: '(Inclusief bestelkosten)',
        cover_the_whole_bill: 'De hele rekening dekken',
        pay_now: 'Nu betalen',
        dismiss: 'Afwijzen',
    },
    hi: {
        current_items: 'वर्तमान ऑर्डर',
        select_items_to_pay_for:
            'कृपया उन आइटम्स पर टैप करें जिनका भुगतान करना चाहते हैं।',
        your_total_colon: 'आपका कुल:',
        including_fee: '(आदेश शुल्क सहित)',
        cover_the_whole_bill: 'पूरा बिल कवर करें',
        pay_now: 'अभी भुगतान करें',
        dismiss: 'खारिज करें',
    },
};
